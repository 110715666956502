import React, { useEffect } from "react";

export default function PrivacyPolicy() {
  useEffect(() => {
    document.title = "Privacy Policy - Tamil Tiles Word Game";
  }, []);

  return (
    <div className="max-w-3xl mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-4">Privacy Policy</h1>
      <p>
        Sibi Sharanyan built the Tamil Tiles Word Game app as an Ad Supported
        app. This SERVICE is provided by Sibi Sharanyan at no cost and is
        intended for use as is.
      </p>
      <p className="mt-4">
        This page is used to inform visitors regarding my policies with the
        collection, use, and disclosure of Personal Information if anyone
        decided to use my Service.
      </p>
      <p className="mt-4">
        If you choose to use my Service, then you agree to the collection and
        use of information in relation to this policy. The Personal Information
        that I collect is used for providing and improving the Service. I will
        not use or share your information with anyone except as described in
        this Privacy Policy.
      </p>
      <p className="mt-4">
        The terms used in this Privacy Policy have the same meanings as in our
        Terms and Conditions, which are accessible at Tamil Tiles Word Game
        unless otherwise defined in this Privacy Policy.
      </p>

      <h2 className="text-xl font-bold mt-8 mb-2">
        Data Collection and Safety
      </h2>
      <p>
        We are committed to ensuring the safety of your data. To comply with
        regulations, we provide a clear and accurate Data Safety section for our
        app detailing the collection, use, and sharing of user data. We are
        responsible for the accuracy of this label and keeping the information
        up-to-date.
      </p>
      <div className="flex flex-col space-y-3 mt-2">
        <p>
          <strong>Collection:</strong> In addition to the name of the user, we now also collect data related to <span className="font-semibold">Location (Approximate location), App Activity (App interactions), App Info and Performance (Crash logs, Diagnostics), and Device or Other Identifiers.</span> This data collection is <span className="font-semibold text-red-600">required</span> for all users, as it's essential for providing our services.
        </p>
        <p>
          <strong>Use:</strong> This information is used not only for providing and enhancing the Service but also for <span className="font-semibold">advertising and marketing purposes.</span>
        </p>
        <p>
          <strong>Sharing:</strong> We share the collected data with <span className="font-semibold">third parties</span> for advertising or marketing purposes. This includes data on your location, app activity, app performance, and device identifiers.
        </p>
        <p>
          <strong>Data Handling:</strong> The collected data is <span className="font-semibold">not processed ephemerally</span> and is an integral part of our operations. Unfortunately, <span className="font-semibold text-red-600">users cannot opt out</span> of this data collection due to its importance for our services and our marketing strategies.
        </p>
        <p>
          <strong>Requesting Removal:</strong> Given the mandatory nature of our data collection, removal requests might be limited. However, if you have concerns about your data, please contact us at <a href="mailto:sibisharanyanit@gmail.com" className="text-blue-600 underline">sibisharanyanit@gmail.com</a>.
        </p>
      </div>


      <h2 className="text-xl font-bold mt-8 mb-2">Log Data</h2>
      <p>
        I want to inform you that whenever you use my Service, in a case of an
        error in the app I collect data and information (through third-party
        products) on your phone called Log Data. This Log Data may include
        information such as your device Internet Protocol (“IP”) address, device
        name, operating system version, the configuration of the app when
        utilizing my Service, the time and date of your use of the Service, and
        other statistics.
      </p>

      <h2 className="text-xl font-bold mt-8 mb-2">Cookies</h2>

      <div className="">
        Cookies are files with a small amount of data that are commonly used as
        anonymous unique identifiers. These are sent to your browser from the
        websites that you visit and are stored on your device's internal memory.
        This Service does not use these “cookies” explicitly. However, the app
        may use third-party code and libraries that use “cookies” to collect
        information and improve their services. You have the option to either
        accept or refuse these cookies and know when a cookie is being sent to
        your device. If you choose to refuse our cookies, you may not be able to
        use some portions of this Service.
      </div>

      <h2 className="text-xl font-bold mt-8 mb-2">Service Providers</h2>
      <p>
        I may employ third-party companies and individuals due to the following
        reasons:
      </p>
      <ul className="list-disc pl-4">
        <li>To facilitate our Service;</li>
        <li>To provide the Service on our behalf;</li>
        <li>To perform Service-related services; or</li>
        <li>To assist us in analyzing how our Service is used.</li>
      </ul>
      <p className="mt-4">
        I want to inform users of this Service that these third parties have
        access to their Personal Information. The reason is to perform the tasks
        assigned to them on our behalf. However, they are obligated not to
        disclose or use the information for any other purpose.
      </p>
      <h2 className="text-xl font-bold mt-8 mb-2">Security</h2>
      <p>
        I value your trust in providing us your Personal Information, thus we
        are striving to use commercially acceptable means of protecting it. But
        remember that no method of transmission over the internet, or method of
        electronic storage is 100% secure and reliable, and I cannot guarantee
        its absolute security.
      </p>
      <h2 className="text-xl font-bold mt-8 mb-2">Links to Other Sites</h2>
      <p>
        This Service may contain links to other sites. If you click on a
        third-party link, you will be directed to that site. Note that these
        external sites are not operated by me. Therefore, I strongly advise you
        to review the Privacy Policy of these websites. I have no control over
        and assume no responsibility for the content, privacy policies, or
        practices of any third-party sites or services.
      </p>
      <h2 className="text-xl font-bold mt-8 mb-2">Children’s Privacy</h2>
      <p>
        Our services are suitable for users of all ages, including children
        under the age of 13. We are committed to protecting the privacy of
        children who use our app. We do not knowingly collect personally
        identifiable information from children under 13 years of age unless it
        is provided with verifiable parental consent. If you are a parent or
        guardian and you believe that your child has provided us with personal
        information, please contact us immediately so that we can take necessary
        actions to obtain parental consent or delete the information.
      </p>
      <h2 className="text-xl font-bold mt-8 mb-2">
        Changes to This Privacy Policy
      </h2>
      <p>
        I may update our Privacy Policy from time to time. Thus, you are advised
        to review this page periodically for any changes. I will notify you of
        any changes by posting the new Privacy Policy on this page.
      </p>
      <p className="mt-4">This policy is effective as of 2023-08-23</p>
      <h2 className="text-xl font-bold mt-8 mb-2">Contact Us</h2>
      <p>
        If you have any questions or suggestions about my Privacy Policy, do not
        hesitate to contact me at{" "}
        <a
          href="mailto:sibisharanyanit@gmail.com"
          className="text-blue-600 underline"
        >
          sibisharanyanit@gmail.com
        </a>
        .
      </p>
    </div>
  );
}
