import { View } from "react-native";
import React, { memo } from "react";
import { Line, Svg } from "react-native-svg";

function WordPath({
  path,
  fixedCellSize,
}: {
  path: { i: number; j: number }[];
  fixedCellSize: number;
}) {
  return (
    <View
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
      }}
    >
      <Svg height="100%" width="100%">
        {path.map((cell, index) => {
          if (index === 0) return null;

          const prevCell = path[index - 1];
          const x1 = prevCell.j * fixedCellSize + fixedCellSize / 2;
          const y1 = prevCell.i * fixedCellSize + fixedCellSize / 2;
          const x2 = cell.j * fixedCellSize + fixedCellSize / 2;
          const y2 = cell.i * fixedCellSize + fixedCellSize / 2;

          return (
            <Line
              x1={x1}
              y1={y1}
              x2={x2}
              y2={y2}
              opacity={0.11}
              stroke="black"
              strokeWidth="6"
              key={`${cell.i},${cell.j}`}
            />
          );
        })}
      </Svg>
    </View>
  );
}

export default memo(WordPath);
