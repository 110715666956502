import React, { useEffect } from "react";
import Home from "./app/Home";
import "./styles.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import PrivacyPolicy from "./app/components/PrivacyPolicy";
import { View } from "react-native";
import Rules from "./app/components/Rules";

export default function App() {
  return (
    <Router>
      <Switch>
        <Route path="/privacy">
          <PrivacyPolicy />
        </Route>
        <Route path="/privacy-policy">
          <PrivacyPolicy />
        </Route>
        <Route path="/">
          <View className="bg-gray-800 w-full flex flex-row select-none">
            <Home />
          </View>
        </Route>
      </Switch>
    </Router>
  );
}
