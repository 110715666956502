import React, { useEffect, useRef } from "react";
import {
  View,
  Text,
  Image,
  Dimensions,
  StyleSheet,
  Modal,
  TouchableOpacity,
} from "react-native";
import { XCircleIcon } from "react-native-heroicons/solid";
import Swiper from "react-native-web-swiper";

const steps = [
  {
    image: require("../../assets/screen1.gif"),
    text: "வார்த்தையை உருவாக்க எந்த திசையிலும் அருகிலுள்ள ஓடுகளைத் தேர்ந்தெடுக்கவும். நீங்கள் சரியான வார்த்தையை உருவாகியிருந்தால், ஓடுகள் பச்சை நிறமாக மாறும்.",
  },
  {
    image: require("../../assets/screen2.gif"),
    text: "தங்க ஓடுகள் சிறப்பு திறன் கொண்டவை. அவை 'உயிர்மெய்' வரிசையில் ஒரு சொல்லில் சரியாகப் பொருந்தும்போது தொடர்புடைய எழுத்துக்கு மாறும்.",
  },
  {
    image: require("../../assets/screen3.png"),
    text: "நீங்கள் பெரும் புள்ளிகள் உங்கள் வார்த்தையின் நீளத்தை அடிப்படையாகக் கொண்டது. அதிகம் புள்ளிகள் பெற நீளமான வார்த்தையை உருவாக்கவும்.",
  },
];

export default function Rules({
  isModalVisible,
  setShowRulesModel,
}: {
  isModalVisible: boolean;
  setShowRulesModel: (value: boolean) => void;
}) {
  const viewRef = useRef<any>(null);

  useEffect(() => {
    const handleTouchMove = (e: TouchEvent) => {
      e.preventDefault();
    };

    const viewElement = viewRef.current;
    if (viewElement) {
      viewElement.addEventListener("touchmove", handleTouchMove, {
        passive: false,
      });

      // Cleanup
      return () => {
        viewElement.removeEventListener("touchmove", handleTouchMove);
      };
    }
  }, [isModalVisible]);

  return (
    <Modal animationType="slide" transparent={true} visible={isModalVisible}>
      <View
        className="flex-1 justify-center items-center bg-black bg-opacity-80"
        ref={viewRef}
      >
        <View
          className="text-center w-full h-full bg-gray-800"
          style={{
            maxHeight: 900,
            maxWidth: 500,
          }}
        >
          <TouchableOpacity
            className="ml-auto p-4"
            onPress={() => {
              setShowRulesModel(false);
            }}
          >
            <XCircleIcon
              width={24}
              height={24}
              className="text-white font-bold "
            />
          </TouchableOpacity>

          <Swiper>
            {steps.map((step, index) => (
              <View key={index} className="flex-1 items-center justify-center">
                <Image
                  source={step.image}
                  className={`w-full h-96`}
                  resizeMode={"contain"}
                />
                <Text
                  className={`text-white text-lg font-semibold px-2.5 mt-7 text-center`}
                >
                  {step.text}
                </Text>
              </View>
            ))}
          </Swiper>
        </View>
      </View>
    </Modal>
  );
}
