export const tamilLetters = {
    "uyir_ezhuthukal": [
        "அ", "ஆ", "இ", "ஈ", "உ", "ஊ", "எ", "ஏ", "ஐ", "ஒ", "ஓ", "ஔ"
    ],
    "mei_ezhuthukal": [
        "க்", "ங்", "ச்", "ஞ்", "ட்", "ண்", "த்", "ந்", "ப்", "ம்", "ய்", "ர்", "ல்", "வ்", "ழ்", "ள்", "ற்", "ன்"
    ],
    "uyirmei_ezhuthukal": [
        [
            "க",
            "கா",
            "கி",
            "கீ",
            "கு",
            "கூ",
            "கெ",
            "கே",
            "கை",
            "கொ",
            "கோ",
            "கௌ"
        ],
        [
            "ங",
            "ஙா",
            "ஙி",
            "ஙீ",
            "ஙு",
            "ஙூ",
            "ஙெ",
            "ஙே",
            "ஙை",
            "ஙொ",
            "ஙோ",
            "ஙௌ"
        ],
        [
            "ச",
            "சா",
            "சி",
            "சீ",
            "சு",
            "சூ",
            "செ",
            "சே",
            "சை",
            "சொ",
            "சோ",
            "சௌ"
        ],
        [
            "ஞ",
            "ஞா",
            "ஞி",
            "ஞீ",
            "ஞு",
            "ஞூ",
            "ஞெ",
            "ஞே",
            "ஞை",
            "ஞொ",
            "ஞோ",
            "ஞௌ"
        ],
        [
            "ட",
            "டா",
            "டி",
            "டீ",
            "டு",
            "டூ",
            "டெ",
            "டே",
            "டை",
            "டொ",
            "டோ",
            "டௌ"
        ],
        [
            "ண",
            "ணா",
            "ணி",
            "ணீ",
            "ணு",
            "ணூ",
            "ணெ",
            "ணே",
            "ணை",
            "ணொ",
            "ணோ",
            "ணௌ"
        ],
        [
            "த",
            "தா",
            "தி",
            "தீ",
            "து",
            "தூ",
            "தெ",
            "தே",
            "தை",
            "தொ",
            "தோ",
            "தௌ"
        ],
        [
            "ந",
            "நா",
            "நி",
            "நீ",
            "நு",
            "நூ",
            "நெ",
            "நே",
            "நை",
            "நொ",
            "நோ",
            "நௌ"
        ],
        [
            "ப",
            "பா",
            "பி",
            "பீ",
            "பு",
            "பூ",
            "பெ",
            "பே",
            "பை",
            "பொ",
            "போ",
            "பௌ"
        ],
        [
            "ம",
            "மா",
            "மி",
            "மீ",
            "மு",
            "மூ",
            "மெ",
            "மே",
            "மை",
            "மொ",
            "மோ",
            "மௌ"
        ],
        [
            "ய",
            "யா",
            "யி",
            "யீ",
            "யு",
            "யூ",
            "யெ",
            "யே",
            "யை",
            "யொ",
            "யோ",
            "யௌ"
        ],
        [
            "ர",
            "ரா",
            "ரி",
            "ரீ",
            "ரு",
            "ரூ",
            "ரெ",
            "ரே",
            "ரை",
            "ரொ",
            "ரோ",
            "ரௌ"
        ],
        [
            "ல",
            "லா",
            "லி",
            "லீ",
            "லு",
            "லூ",
            "லெ",
            "லே",
            "லை",
            "லொ",
            "லோ",
            "லௌ"
        ],
        [
            "வ",
            "வா",
            "வி",
            "வீ",
            "வு",
            "வூ",
            "வெ",
            "வே",
            "வை",
            "வொ",
            "வோ",
            "வௌ"
        ],
        [
            "ழ",
            "ழா",
            "ழி",
            "ழீ",
            "ழு",
            "ழூ",
            "ழெ",
            "ழே",
            "ழை",
            "ழொ",
            "ழோ",
            "ழௌ"
        ],
        [
            "ள",
            "ளா",
            "ளி",
            "ளீ",
            "ளு",
            "ளூ",
            "ளெ",
            "ளே",
            "ளை",
            "ளொ",
            "ளோ",
            "ளௌ"
        ],
        [
            "ற",
            "றா",
            "றி",
            "றீ",
            "று",
            "றூ",
            "றெ",
            "றே",
            "றை",
            "றொ",
            "றோ",
            "றௌ"
        ],
        [
            "ன",
            "னா",
            "னி",
            "னீ",
            "னு",
            "னூ",
            "னெ",
            "னே",
            "னை",
            "னொ",
            "னோ",
            "னௌ"
        ]
    ]
}


export const letterFrequency = {
    "1": 1,
    "8": 1,
    "அ": 4683,
    "கு": 6134,
    "வை": 817,
    "க்": 8221,
    "க": 14648,
    "ட்": 5668,
    "டி": 3782,
    "ரி": 3360,
    "ய": 5000,
    "சா": 1835,
    "ர": 7626,
    "ணை": 551,
    "மி": 1115,
    "ர்": 5482,
    "த": 17139,
    "வ": 6967,
    "ல்": 14041,
    "லி": 1723,
    "ம்": 18107,
    "மா": 2014,
    "று": 2801,
    "ரு": 4504,
    "ன்": 8098,
    "வி": 3786,
    "த்": 9504,
    "சி": 3717,
    "ன": 3549,
    "ள": 1892,
    "சு": 2749,
    "ந்": 3246,
    "தி": 6613,
    "ல": 3951,
    " ": 503,
    "ண்": 3761,
    "டை": 2099,
    "கொ": 1491,
    "ள்": 2417,
    "ளு": 495,
    "ப": 7626,
    "து": 4145,
    "பு": 4270,
    "ழு": 1119,
    "தா": 2099,
    "ம": 5915,
    "ண": 2132,
    "னி": 1216,
    "பி": 2611,
    "லை": 2203,
    "சோ": 380,
    "ழை": 358,
    "மு": 2451,
    "ங்": 3280,
    "ட": 4552,
    "கா": 4254,
    "ற்": 3090,
    "ற": 1811,
    "ளி": 1430,
    "னு": 383,
    "மை": 987,
    "ணி": 1444,
    "னா": 478,
    "ரா": 1452,
    "கி": 2621,
    "னே": 50,
    "ய்": 1896,
    "சீ": 563,
    "ணு": 338,
    "ப்": 5766,
    "பை": 310,
    "றி": 1261,
    "சை": 836,
    "வு": 1516,
    "ஞ்": 1460,
    "ச": 6393,
    "பா": 2696,
    "கே": 357,
    "டு": 5529,
    "யா": 1410,
    "லா": 904,
    "ரோ": 289,
    "ச்": 2806,
    "ழ": 933,
    "யெ": 140,
    "கை": 2110,
    "யோ": 379,
    "நி": 1453,
    "வா": 2554,
    "லு": 606,
    "பே": 453,
    "வொ": 25,
    "யு": 441,
    "றை": 973,
    "நா": 1301,
    "பெ": 761,
    "டா": 791,
    "ந": 1418,
    "னூ": 24,
    "தை": 783,
    "சே": 462,
    "வே": 794,
    "செ": 1109,
    "மொ": 254,
    "ழி": 1227,
    "வோ": 90,
    "தொ": 556,
    "கூ": 798,
    "தீ": 558,
    "கோ": 1259,
    "பூ": 945,
    "ளை": 762,
    "தே": 674,
    "யி": 1079,
    "ஞா": 215,
    "மூ": 555,
    "ரை": 1043,
    "மே": 473,
    "ழ்": 616,
    "கீ": 315,
    "மோ": 195,
    "ரீ": 132,
    "போ": 811,
    "னை": 928,
    "சூ": 477,
    "னீ": 48,
    "ரே": 167,
    "பொ": 1074,
    "வெ": 1031,
    "டோ": 75,
    "ஞ": 93,
    "ணா": 304,
    "தூ": 463,
    "நீ": 577,
    "பீ": 232,
    "தோ": 472,
    "வ்": 156,
    "நு": 182,
    "யே": 87,
    "ஃ": 53,
    "யை": 122,
    "லே": 132,
    "சொ": 461,
    "ரூ": 113,
    "ஆ": 2019,
    "யொ": 39,
    "றா": 310,
    "னோ": 96,
    "கெ": 316,
    "லோ": 233,
    "ளூ": 8,
    "டே": 42,
    "நூ": 159,
    "ஞை": 31,
    "ளா": 222,
    "உ": 2185,
    "யீ": 28,
    "தெ": 465,
    "மீ": 207,
    "ளே": 20,
    "வீ": 429,
    "நெ": 507,
    "ஓ": 326,
    "லூ": 50,
    "¦": 2,
    "ழா": 114,
    "ங": 15,
    "டூ": 48,
    "வூ": 25,
    "நே": 163,
    "ஷ்": 3,
    "னெ": 43,
    "மெ": 251,
    "ணோ": 23,
    "றூ": 19,
    "ளீ": 12,
    "ஸ்": 4,
    "ளெ": 41,
    "நோ": 164,
    "றோ": 50,
    "எ": 1025,
    "றே": 22,
    "லௌ": 2,
    "(": 1,
    ")": 1,
    "ளொ": 10,
    "லெ": 83,
    "ளோ": 23,
    "இ": 2883,
    "றெ": 54,
    "லீ": 44,
    "டெ": 73,
    "யூ": 90,
    "ணொ": 6,
    "ணெ": 70,
    "ரொ": 18,
    "ணீ": 76,
    "றொ": 27,
    "டொ": 15,
    "சௌ": 69,
    "பௌ": 39,
    "டீ": 40,
    "லொ": 20,
    "ரௌ": 10,
    "நொ": 131,
    "ஷி": 3,
    "ஞெ": 42,
    "ஏ": 543,
    "ரெ": 79,
    "ஈ": 222,
    "னொ": 9,
    "ஊ": 393,
    "ஷ": 1,
    "ணே": 13,
    "ணூ": 6,
    "ழீ": 5,
    "ழொ": 1,
    "ஐ": 176,
    "ஞீ": 4,
    "ஞூ": 1,
    "ஒ": 654,
    "ஔ": 33,
    "கௌ": 74,
    "தௌ": 21,
    "டௌ": 1,
    "ழூ": 10,
    "ழோ": 8,
    "ழே": 1,
    "மௌ": 22,
    "ஙு": 2,
    "றீ": 3,
    "ஞி": 7,
    "ஞே": 3,
    "ஞொ": 2,
    "யௌ": 10,
    "நை": 48,
    "நௌ": 3,
    "ழெ": 3,
    "¬": 1
} as { [letter: string]: number };