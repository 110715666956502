import React, { memo } from "react";
import { View, Text, ScrollView } from "react-native";
import { CircleStackIcon } from "react-native-heroicons/solid";
import { charCount, isWeb } from "../util";

const FormedWordsList = memo(({ formedWords }: { formedWords: string[] }) => {
  return (
    <View className="mt-5 p-2.5 rounded-lg bg-gray-200 h-1/4 shadow-md w-full max-w-xs flex items-center">
      {formedWords.length === 0 ? (
        <Text className="leading-6 font-semibold mt-10 text-center w-full">
          நீங்கள் உருவாக்கும் வார்த்தைகள் இங்கே தோன்றும்
        </Text>
      ) : (
        <ScrollView showsVerticalScrollIndicator={true} className="w-full">
          {[...formedWords].reverse().map((word, index) => (
            <View
              key={index}
              className="flex flex-row items-center  px-4 py-2 w-full"
            >
              <Text className="font-semibold text-md w-5/6">{word} </Text>

              <View className="flex flex-row space-x-1 items-center ">
                <CircleStackIcon
                  width={20}
                  height={20}
                  fill="black"
                  opacity={0.9}
                />
                <Text className="font-semibold ">
                  {Math.pow(2, charCount(word))}
                </Text>
              </View>
            </View>
          ))}
        </ScrollView>
      )}
    </View>
  );
});

export default FormedWordsList;
