import { View, Text } from "react-native";
import React, { useEffect } from "react";
import { LinearGradient } from "expo-linear-gradient";
import { useSpring, animated, config } from "@react-spring/web";

const AnimatedLinearGradient = animated(LinearGradient);

function Tile({
  isSelected,
  isValidWord,
  isSpecial,
  isEmpty,
  cellIndex,
  letter,
  isSpecialMatched = false,
}: {
  isSelected: boolean;
  isValidWord: boolean;
  isSpecial: boolean;
  isEmpty: boolean;
  cellIndex: number;
  letter: string;
  isSpecialMatched?: boolean;
}) {
  const [props, api] = useSpring(() => ({
    transform: "scale(1)",
    config: config.stiff,
  }));

  useEffect(() => {
    api.start({
      transform: `scale(${isSelected ? 0.97 : 0.9})`,
      config: { tension: 500, friction: 20 },
    });
  }, [isSelected, api]);

  return (
    <AnimatedLinearGradient
      key={letter + cellIndex}
      colors={
        isSelected
          ? isValidWord
            ? ["#4caf50", "#81c784"]
            : ["#FF9A8B", "#FF6A88"]
          : isSpecial
          ? ["#FFD700", "#FFB300"]
          : isEmpty
          ? ["transparent", "transparent"]
          : ["#FFFFFF", "#E7E7E7"]
      }
      style={{
        transform: props.transform,
        width: "50px",
        height: "50px",
        borderRadius: "8px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        boxShadow: "0px 2px 3px rgba(0, 0, 0, 0.3)",
      }}
    >
      <Text
        style={{
          fontSize: isEmpty ? 0 : 16,
          color: isEmpty ? "#F0F0F0" : "#000",
          fontWeight: "bold",
        }}
      >
        {letter}
      </Text>
    </AnimatedLinearGradient>
  );
}

export default React.memo(Tile);
