import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  View,
  Text,
  PanResponder,
  GestureResponderEvent,
  PanResponderGestureState,
  LayoutChangeEvent,
  Touchable,
  TouchableOpacity,
} from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import {
  TamilLetter,
  charCount,
  findMatchingWord,
  generateTamilGrid,
  getCharAtIndex,
  interpolatePoints,
} from "./util";
import Svg, { Line } from "react-native-svg";
import {
  ClockIcon,
  CircleStackIcon,
  QuestionMarkCircleIcon,
} from "react-native-heroicons/solid";
import GameOverCard from "./components/GameOverCard";
import FormedWordsList from "./components/FormedWordsList";
import Tile from "./components/Tile";
import GridRow from "./components/GridRow";
import WordPath from "./components/WordPath";
import { StatusBar } from "expo-status-bar";
import WordFormed from "./components/WordFormed";
import Rules from "./components/Rules";
export interface Cell {
  i: number;
  j: number;
}

const fixedCellSize = 50; //TODO: Might need to change this to be dynamic

const TIME = 90;
const SPECIAL_LETTERS = 0.2;
const MAX_CHAR_COUNT = 7;

export default function Home() {
  const viewRef = useRef<any>(null);

  useEffect(() => {
    const handleTouchMove = (e: TouchEvent) => {
      e.preventDefault();
    };

    const viewElement = viewRef.current;
    if (viewElement) {
      viewElement.addEventListener("touchmove", handleTouchMove, {
        passive: false,
      });

      // Cleanup
      return () => {
        viewElement.removeEventListener("touchmove", handleTouchMove);
      };
    }
  }, []);

  const [grid, setGrid] = useState<TamilLetter[][]>(
    generateTamilGrid(7, SPECIAL_LETTERS)
  );
  const [cellSize, setCellSize] = useState<number | null>(14);
  const [selectedCells, setSelectedCells] = useState<Set<string>>(new Set());
  const [path, setPath] = useState<Cell[]>([]);
  const [lastCell, setLastCell] = useState<Cell | null>(null);
  const [formedWords, setFormedWords] = useState<string[]>([]);
  const [isValidWord, setIsValidWord] = useState(false);
  const [matchedWord, setMatchedWord] = useState<string>("");

  const [timer, setTimer] = useState(TIME);
  const [isGameActive, setIsGameActive] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [showRulesModel, setShowRulesModel] = useState(false);

  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    if (isGameActive) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer === 1) {
            clearInterval(intervalId);
            setIsGameActive(false);
            setIsModalVisible(true);
          }
          return prevTimer - 1;
        });
      }, 1000);
    }
    return () => clearInterval(intervalId);
  }, [isGameActive]);

  const pointsScored = formedWords.reduce((acc, word) => {
    return acc + Math.pow(2, charCount(word));
  }, 0);

  const longestWord = formedWords.reduce(
    (a, b) => (charCount(a) > charCount(b) ? a : b),
    ""
  );

  const handlePlayAgain = () => {
    setIsModalVisible(false);
    setTimer(TIME);
    setFormedWords([]);
    setGrid(generateTamilGrid(7, SPECIAL_LETTERS));
    setIsGameActive(true);
  };

  const [gridOffsetX, setGridOffsetX] = useState(0);
  const [gridOffsetY, setGridOffsetY] = useState(0);

  const panResponder = PanResponder.create({
    onStartShouldSetPanResponder: () => true,
    onPanResponderTerminationRequest: () => true,
    onPanResponderMove: (evt, gestureState) =>
      handlePanResponderMove(evt, gestureState),
    onPanResponderRelease: () => handlePanResponderEnd(),
  });

  function handleLayout(event: LayoutChangeEvent) {
    const { width } = event.nativeEvent.layout;
    // setCellSize(width / 7); // 7 is the number of columns

    // console.log("Cell Size:", width / 7, width);
  }

  useEffect(() => {
    setMatchedWord("");
    if (Array.from(selectedCells).length > MAX_CHAR_COUNT) {
      setSelectedCells(new Set());
      return;
    }

    const newPath = Array.from(selectedCells).map((cellId) => {
      const [i, j] = cellId.split(",").map(Number);
      return { i, j };
    });
    setPath(newPath);

    const wordFormed = Array.from(selectedCells)
      .map((cellId) => {
        if (cellId === "NaN,NaN") {
          return null;
        }
        const [i, j] = cellId.split(",").map(Number);
        return grid[i][j];
      })
      .filter((cell) => cell !== null) as TamilLetter[];

    const matchedWord = findMatchingWord(wordFormed);
    if (matchedWord) setMatchedWord(matchedWord);
    if (wordFormed.length > 1) setIsValidWord(Boolean(matchedWord));
  }, [selectedCells]);

  function handlePanResponderMove(
    evt: GestureResponderEvent,
    gestureState: PanResponderGestureState
  ) {
    const i = Math.floor((gestureState.moveY - gridOffsetY) / fixedCellSize);
    const j = Math.floor((gestureState.moveX - gridOffsetX) / fixedCellSize);

    const cellCenterY = i * fixedCellSize + fixedCellSize / 2 + gridOffsetY;
    const cellCenterX = j * fixedCellSize + fixedCellSize / 2 + gridOffsetX;

    const distance = Math.sqrt(
      Math.pow(gestureState.moveY - cellCenterY, 2) +
        Math.pow(gestureState.moveX - cellCenterX, 2)
    );

    if (distance > fixedCellSize / 2) {
      return;
    }

    if (i >= 0 && j >= 0 && i < grid.length && j < grid[0].length) {
      if (grid[i][j] === null) {
        setPath([]);
        setSelectedCells(new Set());
        return;
      }
      const cellId = `${i},${j}`;

      if (
        selectedCells.has(cellId) &&
        !(lastCell?.i === i && lastCell?.j === j)
      ) {
        setPath([]);
        setSelectedCells(new Set());
        return;
      }

      if (lastCell) {
        const pointsToSelect = interpolatePoints(lastCell, { i, j });

        //check if any value in pointsToSelect is already selected

        for (let point of pointsToSelect) {
          const cellId = point;
          const [i, j] = cellId.split(",").map(Number);

          if (!grid[i][j]) {
            setPath([]);
            setSelectedCells(new Set());
            return;
          }

          if (
            selectedCells.has(cellId) &&
            !(lastCell?.i === i && lastCell?.j === j)
          ) {
            setPath([]);
            setSelectedCells(new Set());
            return;
          }
        }

        setSelectedCells((prev) => {
          const newSet = new Set(prev);
          for (let point of pointsToSelect) {
            newSet.add(point);
          }
          return newSet;
        });
      } else {
        setSelectedCells((prev) => new Set(prev).add(cellId));
      }
      setLastCell({ i, j });
    }
  }

  function handlePanResponderEnd() {
    const wordFormed = Array.from(selectedCells)
      .map((cellId) => {
        if (cellId === "NaN,NaN") {
          return null;
        }
        const [i, j] = cellId.split(",").map(Number);
        return grid[i][j];
      })
      .filter((cell) => cell !== null) as TamilLetter[];

    const matchedWord = findMatchingWord(wordFormed);

    if (matchedWord) {
      let newGrid = JSON.parse(JSON.stringify(grid));

      const columns = newGrid[0].length;

      for (let j = 0; j < columns; j++) {
        // Find the cells in the column that should fall down (are not selected)
        const fallingCells = [];
        for (let i = newGrid.length - 1; i >= 0; i--) {
          const cellId = `${i},${j}`;
          if (!selectedCells.has(cellId)) {
            fallingCells.push(newGrid[i][j]);
          }
        }

        // Fill in the falling cells at the bottom of the column in the new grid
        for (let i = 0; i < fallingCells.length; i++) {
          newGrid[newGrid.length - 1 - i][j] = fallingCells[i];
        }

        // Set the remaining cells in the column to null
        for (let i = fallingCells.length; i < newGrid.length; i++) {
          newGrid[newGrid.length - 1 - i][j] = null;
        }
      }

      // Update the grid state
      setGrid(newGrid);
      setFormedWords((prevWords) => [...prevWords, matchedWord]);
    }

    // Reset the selected cells and last cell states
    setSelectedCells(new Set());
    setLastCell(null);
    setPath([]);
    setIsValidWord(false);
  }

  return (
    <View className="px-5 bg-gray-800 h-full w-full flex items-center min-w-sm">
      <TouchableOpacity
        className="ml-auto p-2"
        onPress={() => {
          setShowRulesModel(true);
        }}
      >
        <QuestionMarkCircleIcon width={24} height={24} fill="#fff" />
      </TouchableOpacity>

      <View className="flex flex-row justify-between pt-7  w-full max-w-sm">
        <View className="mb-4 flex flex-row space-x-2 items-center">
          <ClockIcon width={24} height={24} fill="#fff" />
          <Text style={{ fontSize: 18, fontWeight: "bold", color: "#FFFFFF" }}>
            {timer}
          </Text>
        </View>

        <WordFormed
          isValidWord={isValidWord}
          matchedWord={matchedWord}
          selectedCells={selectedCells}
          grid={grid}
        />

        <View className="mb-4 flex flex-row space-x-2 items-center">
          <CircleStackIcon width={24} height={24} fill="#fff" />
          <Text style={{ fontSize: 18, fontWeight: "bold", color: "#FFFFFF" }}>
            {pointsScored}
          </Text>
        </View>
      </View>

      <View
        ref={viewRef}
        className="mt-4"
        {...panResponder.panHandlers}
        onLayout={(event) => {
          const { x, y } = event.nativeEvent.layout;
          setGridOffsetX(x);
          setGridOffsetY(y);
        }}
      >
        {grid.map((row, rowIndex) => (
          <GridRow
            key={rowIndex}
            rowIndex={rowIndex}
            row={row}
            grid={grid}
            selectedCells={selectedCells}
            isValidWord={isValidWord}
            matchedWord={matchedWord}
          />
        ))}

        <WordPath path={path} fixedCellSize={fixedCellSize} />
      </View>

      <FormedWordsList formedWords={formedWords} />

      <GameOverCard
        isModalVisible={isModalVisible}
        pointsScored={pointsScored}
        formedWords={formedWords}
        longestWord={longestWord}
        handlePlayAgain={handlePlayAgain}
      />

      <Rules
        isModalVisible={showRulesModel}
        setShowRulesModel={setShowRulesModel}
      />

      <StatusBar style="dark" />
    </View>
  );
}
