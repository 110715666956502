import { Platform } from "react-native";
import posthog from 'posthog-js'
import { Cell } from "../Home";
import { letterFrequency, tamilLetters } from "./letters";
import { words } from "./words";

type TamilLetterCategories = {
    uyir_ezhuthukal: string[];
    mei_ezhuthukal: string[];
    uyirmei_ezhuthukal: string[][];
};

export interface TamilLetter {
    letter: string;
    category: string;
}

// const diacritics = {
//     '\u0B82': true,
//     '\u0BBE': true,
//     '\u0BBF': true,
//     '\u0BC0': true,
//     '\u0BC1': true,
//     '\u0BC2': true,
//     '\u0BC6': true,
//     '\u0BC7': true,
//     '\u0BC8': true,
//     '\u0BCA': true,
//     '\u0BCB': true,
//     '\u0BCC': true,
//     '\u0BCD': true,
//     '\u0BD7': true
// } as any;

// function splitTamilWord(word: string): string[] {
//     const str1 = [...word];
//     const tamilLetters = [];
//     for (let i = 0; i !== str1.length; ++i) {
//         const ch = str1[i];
//         diacritics[ch] ? (tamilLetters[tamilLetters.length - 1] += ch) : tamilLetters.push(ch);
//     }
//     return tamilLetters;
// }

// export function computeLetterFrequency(words: string[]): { [letter: string]: number } {
//     const frequency: { [letter: string]: number } = {};

//     words.forEach(word => {
//         const letters = splitTamilWord(word);
//         for (const letter of letters) {
//             frequency[letter] = (frequency[letter] || 0) + 1;
//         }
//     });

//     return frequency;
// }

export function generateTamilGrid(gridSize: number = 7, specialPercent: number = 0.4): TamilLetter[][] {
    // Sort letters based on frequency
    const sortedLetters = Object.entries(letterFrequency).sort((a, b) => b[1] - a[1]).map(entry => entry[0]);

    // Prepare grid
    const grid: TamilLetter[][] = Array.from({ length: gridSize }, () => Array(gridSize).fill({ letter: '', category: 'normal' }));

    // Generate a list of all coordinates.
    const allCoords: { i: number, j: number }[] = [];
    for (let i = 0; i < gridSize; i++) {
        for (let j = 0; j < gridSize; j++) {
            allCoords.push({ i, j });
        }
    }

    // Shuffle the coordinates.
    allCoords.sort(() => Math.random() - 0.5);

    const totalCells = gridSize * gridSize;
    const specialCellsCount = Math.floor(specialPercent * totalCells);

    // Identify the special letters that frequently appear in the given word list
    const commonSpecialLetters = tamilLetters.uyirmei_ezhuthukal.map(series => series[0]).filter(letter => letterFrequency[letter]);
    commonSpecialLetters.sort((a, b) => (letterFrequency[b] || 0) - (letterFrequency[a] || 0));

    // Place the most common special characters first
    for (let k = 0; k < specialCellsCount; k++) {
        const { i, j } = allCoords[k];
        grid[i][j] = {
            letter: commonSpecialLetters[k % commonSpecialLetters.length],
            category: 'special',
        };
    }

    // Fill grid with the most frequent letters
    let letterIndex = 0;
    for (let k = specialCellsCount; k < totalCells; k++) {
        const { i, j } = allCoords[k];
        if (grid[i][j].letter !== '') continue;

        grid[i][j] = {
            letter: sortedLetters[letterIndex % sortedLetters.length],
            category: 'normal',
        };
        letterIndex++;
    }

    return grid;
}



const wordsSet = new Set(words);
const globalCache = new Map<string, string | null>();

export function findMatchingWord(tamilLetterArray: TamilLetter[]): string | null | undefined {
    if (tamilLetterArray.length === 1) {
        return null;
    }

    const baseWord = tamilLetterArray.map(t => t.letter).join('');
    if (wordsSet.has(baseWord)) {
        return baseWord;
    }

    function checkCombinations(index: number, currentArray: TamilLetter[]): string | null | undefined {
        const key = currentArray.map(t => t.letter).join('');
        if (globalCache.has(key)) {
            return globalCache.get(key);
        }

        if (index === tamilLetterArray.length) {
            const word = key;
            if (wordsSet.has(word)) {
                globalCache.set(key, word);
                return word;
            }
            globalCache.set(key, null);
            return null;
        }

        if (currentArray[index].category !== "special") {
            return checkCombinations(index + 1, currentArray);
        }

        const uyirmeiSeries = tamilLetters.uyirmei_ezhuthukal.find(series =>
            series.includes(currentArray[index].letter)
        );

        if (uyirmeiSeries) {
            const originalLetter = currentArray[index];
            for (const uyirmeiLetter of uyirmeiSeries) {
                currentArray[index] = { ...originalLetter, letter: uyirmeiLetter };
                const result = checkCombinations(index + 1, currentArray);
                if (result) {
                    globalCache.set(key, result);
                    currentArray[index] = originalLetter;
                    return result;
                }
            }
            currentArray[index] = originalLetter;
        }
        globalCache.set(key, null);
        return null;
    }

    return checkCombinations(0, tamilLetterArray);
}
var re = /[\u0300-\u036f\u1dc0-\u1dff\u20d0-\u20ff\ufe20-\ufe2f\u0b82\u0b83\u0bbe\u0bbf\u0bc0-\u0bc2\u0bc6-\u0bc8\u0bca-\u0bcd\u0bd7]/g

export const charCount = (str: string) => {
    return str.replace(re, "").length;
}

export const getCharAtIndex = (str: string, index: number) => {
    const match = str.match(/[\u0b80-\u0bff][\u0bbe-\u0bcd\u0bd7]?/gi);
    return match && match[index] ? match[index] : '';
}


export const interpolatePoints = (start: Cell, end: Cell) => {
    const interpolatedPoints = new Set<string>();

    const dx = end.j - start.j;
    const dy = end.i - start.i;
    const distance = Math.sqrt(dx * dx + dy * dy);
    const steps = Math.ceil(distance);

    const tolerance = 0.5; // Adjust this value to find the optimal tolerance for your grid

    for (let step = 0; step <= steps; step++) {
        const t = step / steps;
        const interpolatedI = start.i + t * dy;
        const interpolatedJ = start.j + t * dx;

        // Find the closest grid points to the interpolated point
        const closestI = Math.round(interpolatedI);
        const closestJ = Math.round(interpolatedJ);

        // Calculate the distances to the closest grid points
        const distI = Math.abs(interpolatedI - closestI);
        const distJ = Math.abs(interpolatedJ - closestJ);

        // Only add the point to the set if it is within the tolerance
        if (distI < tolerance && distJ < tolerance) {
            interpolatedPoints.add(`${closestI},${closestJ}`);
        }
    }

    return interpolatedPoints;
}

export const isWeb = Platform.OS === 'web';


posthog.init('phc_tXNGmxV6K8FueTMgOKhtTE5TrsPf5wraMqQxPV2HqbT', { api_host: 'https://app.posthog.com' })