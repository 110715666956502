import { View, Text, Share } from "react-native";
import React from "react";
import { Modal, TouchableOpacity } from "react-native";
import { ClockIcon, CircleStackIcon } from "react-native-heroicons/solid";

// const handleShare = async (pointsScored: number) => {
//   try {
//     await Share.share({
//       message: `நான் தமிழ் விளையாட்டில் ${pointsScored} புள்ளிகள் பெற்றேன்! நீ என் முடிவை விட முதலிடம் பெற முடியுமா?`,
//     });
//   } catch (error) {
//     // console.log(error);
//   }
// };

export default function GameOverCard({
  isModalVisible,
  pointsScored,
  formedWords,
  longestWord,
  handlePlayAgain,
}: {
  isModalVisible: boolean;
  pointsScored: number;
  formedWords: string[];
  longestWord: string;
  handlePlayAgain: () => void;
}) {
  return (
    <Modal animationType="slide" transparent={true} visible={isModalVisible}>
      <View
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(0,0,0,0.5)",
        }}
      >
        <View className="w-[300px] p-5 bg-gray-800 rounded-lg items-center space-y-6">
          <Text className="text-lg mb-2 text-white font-semibold">
            விளையாட்டு முடிந்தது!
          </Text>

          <View className="space-y-4 flex flex-col items-start">
            <View className="flex flex-row space-x-1 items-center ">
              <CircleStackIcon
                width={24}
                height={24}
                fill="white"
                opacity={0.9}
              />
              <Text className="font-semibold text-white text-lg">
                {pointsScored}
              </Text>
            </View>

            <View className="space-y-1">
              <Text className=" text-white text-md">சிறந்த வார்த்தை:</Text>
              <Text className=" text-white font-semibold text-lg">
                {longestWord}
              </Text>
            </View>
          </View>
          <View className="flex flex-row space-x-4">
            <TouchableOpacity
              style={{
                backgroundColor: "#4CAF50",
                borderRadius: 8,
                paddingVertical: 10,
                paddingHorizontal: 12,
              }}
              onPress={handlePlayAgain}
            >
              <Text className="text-lg font-semibold text-white">
                மீண்டும் விளையாடு
              </Text>
            </TouchableOpacity>
            {/* <TouchableOpacity
              style={{
                backgroundColor: "#008CBA",
                borderRadius: 8,
                paddingVertical: 10,
                paddingHorizontal: 12,
              }}
              onPress={() => {
                handleShare(pointsScored);
              }}
            >
              <Text style={{ fontSize: 16, color: "#FFFFFF" }}>பகிர்</Text>
            </TouchableOpacity> */}
          </View>
        </View>
      </View>
    </Modal>
  );
}
