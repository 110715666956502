import { View } from "react-native";
import React, { memo } from "react";
import Tile from "./Tile";
import { getCharAtIndex } from "../util";

const GridRow = ({
  rowIndex,
  row,
  grid,
  selectedCells,
  isValidWord,
  matchedWord,
}: {
  rowIndex: number;
  row: any[];
  grid: any[][];
  selectedCells: Set<string>;
  isValidWord: boolean;
  matchedWord: string;
}) => {
  return (
    <View key={rowIndex} style={{ flexDirection: "row" }}>
      {row.map((cell, cellIndex) => {
        const isSelected = selectedCells.has(`${rowIndex},${cellIndex}`);
        const isSpecial = grid[rowIndex][cellIndex]?.category === "special";
        const isEmpty = grid[rowIndex][cellIndex] === null;
        const currentCell = { i: rowIndex, j: cellIndex };
        const letterPresentIndex = Array.from(selectedCells).findIndex(
          (x) => x === `${rowIndex},${cellIndex}`
        );

        // if (letterPresentIndex !== -1)
        //   console.log(
        //     "letterPresentIndex",
        //     Array.from(selectedCells),
        //     `${rowIndex},${cellIndex}`,
        //     letterPresentIndex,
        //     matchedWord,
        //     getCharAtIndex(matchedWord, letterPresentIndex)
        //   );

        return (
          <Tile
            key={`${rowIndex},${cellIndex}`}
            isSelected={isSelected}
            isValidWord={isValidWord}
            isSpecial={isSpecial}
            isEmpty={isEmpty}
            cellIndex={cellIndex}
            isSpecialMatched={
              isSpecial && letterPresentIndex !== -1 && isValidWord
            }
            letter={
              grid[rowIndex][cellIndex]
                ? isValidWord && letterPresentIndex !== -1
                  ? getCharAtIndex(matchedWord, letterPresentIndex) ||
                    grid[rowIndex][cellIndex].letter
                  : grid[rowIndex][cellIndex].letter
                : ""
            }
          />
        );
      })}
    </View>
  );
};

export default memo(GridRow);
