import React, { useEffect, useMemo } from "react";
import { animated, config, useSpring } from "@react-spring/web";
import { Text, View } from "react-native";

const AnimatedText = animated(Text);

const WordFormed = ({
  isValidWord,
  matchedWord,
  selectedCells,
  grid,
}: {
  isValidWord: boolean;
  matchedWord: string;
  selectedCells: Set<string>;
  grid: any[][];
}) => {
  const [props, api] = useSpring(() => ({
    scale: 1, // default scale
    color: "#FFFFFF", // default color
    config: config.stiff, // Using a predefined stiff config for a faster animation
  }));

  useEffect(() => {
    api.start({
      scale: isValidWord ? 1.2 : 1, // A little bounce effect
      color: isValidWord ? "#02f79f" : "#FFFFFF", // Green when valid, White otherwise
      config: { tension: 500, friction: 20 },
    });
  }, [isValidWord]);

  const wordToDisplay = useMemo(() => {
    if (isValidWord) {
      return matchedWord;
    }
    return Array.from(selectedCells).map((x) => {
      const [i, j] = x.split(",").map(Number);
      return grid[i][j]?.letter;
    });
  }, [isValidWord, matchedWord, selectedCells, grid]);

  return (
    <View>
      <AnimatedText
        style={{
          transform: `scale(${props.scale})`, // apply scale animation using template string
          color: props.color, // apply color animation
          fontSize: "1.5rem", // equivalent to "text-lg"
          fontWeight: "bold", // equivalent to "font-bold"
        }}
      >
        {wordToDisplay}
      </AnimatedText>
    </View>
  );
};

export default React.memo(WordFormed);
